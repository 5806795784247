<template>
	<div ref="element" class="h-100">
		<simple-loading
			:is-loading="isLoading"
			:is-load-failed="loadFail"
			:showLoadingCard="true"
			@reload="fetchList"
		>
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<el-button
						style="float: right"
						type="primary"
						icon="el-icon-plus"
						@click="onCreateAccountClick"
						>Create Account</el-button
					>
				</div>
				<el-table
					@row-click="onRowClick"
					:data="accounts"
					style="width: 100%"
					:height="size.height - 140 + 'px'"
				>
					<!-- <el-table-column prop="userId" label="User Id"> </el-table-column> -->

					<el-table-column
						prop="fullName"
						label="Full Name"
						width="300"
					>
						<template slot-scope="scope">
							<div class="icon-container">
								<div>
									<avatar :username="scope.row.username" />
								</div>
								<div>
									<h4>{{ scope.row.fullName }}</h4>
									<div>({{ scope.row.username }})</div>
                  <div class="required" v-if="!scope.row.enabled">Account Disabled</div>
                  <div class="required" v-if="scope.row.locked">Account Locked</div>
								</div>
							</div>
						</template>
					</el-table-column>

					<el-table-column
						prop="hasManagement"
						label="Management"
						header-align="center"
					>
						<template slot-scope="scope">
							<div class="center">
								<el-checkbox :value="scope.row.hasManagement" />
							</div>
						</template>
					</el-table-column>

					<el-table-column
						prop="hasSortation"
						label="Sortation"
						header-align="center"
					>
						<template slot-scope="scope">
							<div class="center">
								<el-checkbox :value="scope.row.hasSortation" />
							</div>
						</template>
					</el-table-column>

					<el-table-column
						prop="hasFulfillment"
						label="Fulfillment"
						header-align="center"
					>
						<template slot-scope="scope">
							<div class="center">
								<el-checkbox
									:value="scope.row.hasFulfillment"
								/>
							</div>
						</template>
					</el-table-column>

					<el-table-column
						prop="hasKParcel"
						label="KParcel"
						header-align="center"
					>
						<template slot-scope="scope">
							<div class="center">
								<el-checkbox :value="scope.row.hasKParcel" />
							</div>
						</template>
					</el-table-column>

					<el-table-column
						prop="hasJira"
						label="Jira"
						header-align="center"
					>
						<template slot-scope="scope">
							<div class="center">
								<el-checkbox :value="scope.row.hasJira" />
							</div>
						</template>
					</el-table-column>

					<el-table-column
						prop="hasSetting"
						label="Setting"
						header-align="center"
					>
						<template slot-scope="scope">
							<div class="center">
								<el-checkbox :value="scope.row.hasSetting" />
							</div>
						</template>
					</el-table-column>

					<el-table-column
						prop="hasCrm"
						label="CRM"
						header-align="center"
					>
						<template slot-scope="scope">
							<div class="center">
								<el-checkbox :value="scope.row.hasCrm" />
							</div>
						</template>
					</el-table-column>

          <el-table-column
            prop="hasErp"
            label="ERP"
            header-align="center"
          >
            <template slot-scope="scope">
              <div class="center">
                <el-checkbox :value="scope.row.hasErp" />
              </div>
            </template>
          </el-table-column>
				</el-table>
			</el-card>
		</simple-loading>
	</div>
</template>

<script>
	import { computed, onMounted, ref } from "@vue/composition-api";
	import { fetcher, FetchMethod } from "@/libs/axios";
	import apis from "@configs/apis";
	import sizeHook from "@common-components/sizeHook";
	import Avatar from "vue-avatar";
	import SimpleLoading from "@common-components/simple-loading/SimpleLoading.vue";

	export default {
		components: {
			Avatar,
			SimpleLoading,
		},
		setup(props, ctx) {
			const [size, element] = sizeHook();

			const accounts = ref([]);
			const isLoading = ref(false);
			const loadFail = ref(false);

			onMounted(() => {
				fetchList();
			});

			const onRowClick = (data) => {
				ctx.root.$router.push({
					name: "setting-account-detail",
					params: {
						userId: data.userId,
					},
				});
			};

			const onCreateAccountClick = () => {
				ctx.root.$router.push({
					name: "setting-create-account",
				});
			};

			const fetchList = async () => {
				isLoading.value = true;
				loadFail.value = false;

				try {
					const response = await fetcher(
						apis.accountList,
						FetchMethod.GET
					);

					accounts.value = response;
				} catch (e) {
					loadFail.value = true;
				} finally {
					isLoading.value = false;
				}
			};

			return {
				size,
				element,

				accounts,
				isLoading,
				loadFail,

				onRowClick,
				fetchList,
				onCreateAccountClick,
			};
		},
	};
</script>

<style scoped>
	.center {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.icon-container {
		display: flex;
		align-items: center;
		gap: 20px;
	}
</style>
